import React, { useState, useCallback } from "react"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO/SEO"
import TextAreaEmoticons from "./components/textareaAddEmotes"
import SeeAlso from "../../components/SeeAlso/SeeAlso"
import NavBar from "../../components/NavBar/NavBar"
import ScrollEmoticonsList from "./components/emoticonsReactWindowScroll"

import {
  Title,
  Dropdown,
  Icon,
  Button,
  ArrowDown,
} from "../../StyleComponents/styles"
import Popup from "./components/Popup"
import { isSSR } from "../../components/Script/script"

/* import can be lazy load  */
const DropDownList = React.lazy(() => import("./components/dropDownComponent"))

const seeAlsoArray = [
  "/emoji-copy-and-paste",
  "/font-generator",
  "/html-entity",
  "/text-art",
]

function EmoticonsCopy(props) {
  const { node, allPages } = props.pageContext
  let [emoji, setEmoji] = useState("")
  let [show, setShow] = useState(false)

  let [valueInput, setValueInput] = useState(() => {
    return typeof window !== "undefined"
      ? localStorage.getItem("Emoticons") || ""
      : ""
  })

  const mycopy = useCallback(e => {
    let emoji = e.target.value
    // e.target.select()
    // let copied = document.execCommand("copy")
    // if (copied) {
    setEmoji(emoji)
    setShow(true)
    // setTimeout(function () {
    //   setShow(false)
    // }, 1500)
    // }
  }, [])

  function addTOlistFunction() {
    setValueInput(text => text + emoji)
    setShow(false)
  }

  return (
    <Layout location={props.location}>
      <SEO
        title={`${node.title} ✂️Copy 📋Paste Emoticons ✌(-‿-)✌`}
        description={
          node.title +
          " " +
          node.emoction[0] +
          " ✂️Copy 📋Paste Emoticons ✌(-‿-)✌ - °◦=͟͟͞͞ʕ̡̢̡ु•̫͡•ʔ̡̢̡ु ☏ Simple beautiful Copy Paste Emoticons text Symbols [̲̅$̲̅(̲̅ ͡° ͜ʖ ͡°̲̅)̲̅$̲̅], ☁ Clouds Emoticons ,WTF Emoticons ༎ຶ‿༎ຶ, ( ื▿ ืʃƪ)  smiley Emoticons ..."
        }
        keywords={[
          `${node.name} Emoticons, ${node.name} emotes,${
            node.name
          } emotes copy paste,text Symbols, Copy Paste Emoticons,Emoticons copy,Emoticons Symbols,Emoticons People ,Sunglasses Emoticons, Angry Emoticons, nice Emoticons,${new Date().getFullYear()} Emoticons.`,
        ]}
      />
      <NavBar
        listPages={React.useMemo(() => {
          return [
            { name: "Utility Tools", link: "/utility-tools/" },
            { name: "✂ Emoticons", link: "/emoticons-copy-and-paste/" },
            {
              name: node.name,
              link: `/emoticons-copy-and-paste/${node.name.toLowerCase()}-emoticons/`,
            },
          ]
        }, [node.name])}
      />
      <section className="container">
        <Title id="listOfEmoji">{node.title} Emoticons</Title>
        {show && (
          <Popup
            emoji={emoji}
            addToList={addTOlistFunction}
            close={() => setShow(false)}
          />
        )}
        <div>
          <TextAreaEmoticons
            valueInput={valueInput}
            setValueInput={setValueInput}
          />
          <br />
          <Dropdown className="mb-4">
            <Button aria-haspopup="true" aria-controls="dropdown-menu">
              Emoticons Category
              <Icon>
                <ArrowDown />
              </Icon>
            </Button>
            {!isSSR && (
              <React.Suspense fallback={<div />}>
                <DropDownList list={allPages} />
              </React.Suspense>
            )}
          </Dropdown>
        </div>

        <ScrollEmoticonsList pageItems={node.emoction} mycopy={mycopy} />

        <br />
        <p>
          {node.title} Emoticons, we help you to find cute fancy emoticons, just
          click on an emoticon to copy it to the clipboard and paste them
          wherever you want, such as on Twitter, Facebook, Instagram etcetera.
          Also, write your text above in the text area and add emoticons to it,
          just click on the emoticons you want to add it and click the button
          "Add to Text Area".
        </p>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </section>
    </Layout>
  )
}

export default EmoticonsCopy
